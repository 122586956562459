import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import "./Styles.css";
import "./Navbar.css";

function KioskRouter() {
  const [selectedButton, setSelectedButton] = useState("Button2");
  const navigate = useNavigate();
  const location = useLocation();

  // Map path to button name
  const pathToButtonMap = {
    "kiosk/comics": "Button2",
    "kiosk/trinkets": "Button3",
    "kiosk/raffles": "Button4",
    "market/comics": "Button2",
    "market/trinkets": "Button3",
    "market/raffles": "Button4",
    KioskRouter: "Button5",
    "/": "Button5",
  };
  // Update selectedButton when the location changes
  useEffect(() => {
    const currentPath = location.pathname.replace(/^\/|\/$/g, "");

    setSelectedButton(pathToButtonMap[currentPath] || "Button2");
  }, [location, pathToButtonMap]);

  const handleButtonClick = (buttonName, path) => {
    setSelectedButton(buttonName);
    navigate(path);
  };

  return (
    <div className="marvincontainer">
      <div className="floating-buttons">
        <button
          className={`nav-link ${selectedButton === "Button2" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button2", "comics")}
        >
          <h5>COMICS</h5>
        </button>
        <button
          className={`nav-link ${selectedButton === "Button3" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button3", "trinkets")}
        >
          <h5>JUNK</h5>
        </button>
        <button
          className={`nav-link ${selectedButton === "Button4" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button4", "raffles")}
        >
          <h5>RAFFLES</h5>
        </button>
        {/* <button
          className={`nav-link ${selectedButton === "Button5" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button5", "specials")}
        >
          <h5>BENEFITS</h5>
        </button>  */}
        <div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleButtonClick("Button5", "/")}
          ></button>
        </div>
      </div>
      <div className="component-container">
        <Outlet />
      </div>
    </div>
  );
}

export default KioskRouter;
