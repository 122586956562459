import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import AssetBoxForSwap from "../assetboxoptimal.tsx";
import "./comicclaim.css";
import { Link } from "react-router-dom";
import { useWallet } from "@txnlab/use-wallet";
import { useWalletInfo } from "../utils/UserWalletHook.js";
import { toast } from "react-toastify";
import axios from "axios";
import { encode } from "uint8-to-base64";
import algosdk from "algosdk";
import { COMIC_BUY_FEE, API_ENDPOINT, API_ENDPOINT2 } from "../../constants.ts";
import { merge_comic_tx } from "../utilities.ts";
import success from "../../assets/sounds/success.mp3";
import traitshop from "../../assets/collections/traitshop.jpg";
import ConnectDropdown from "../ConnectDropdown";

const ComicClaim = () => {
  const { key } = useParams(); // Capture the key from the URL
  const traits = [
    { id: 2723359378, name: "Acidlo", description: "A halo made of acid" },
    {
      id: 2723360543,
      name: "Cube",
      description: "A body showing the force cube",
    },
    { id: 2723365904, name: "Forked", description: "A red forked eyes" },
  ];
  const [clickedTrait, setClickedTrait] = useState(null);
  const { walletAddress } = useWalletInfo();
  const [isBuyClicked, setIsBuyClicked] = useState(false);
  const [isMergeDone, setIsMergeDone] = useState(false);
  const { signTransactions } = useWallet();
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleTraitClick = (trait) => {
    setClickedTrait(trait);
  };
  const playSound = (sound) => {
    const audio = new Audio(sound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  async function handleComicMerge() {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }
    setIsBuyClicked(true);

    try {
      const response = await axios.get(`${API_ENDPOINT}/merge-comic/`, {
        params: {
          key: key, // Pass Key that will pick and return Asset (comic) ID
        },
      });
      const comic_merge_id = response.data.comic_id;

      // Ensure we have a valid trinket ID and reserve address
      if (!comic_merge_id) {
        toast.info("Failed to retrieve your comic data.");
        setIsBuyClicked(false);
        return;
      }

      const transactions = await merge_comic_tx(
        walletAddress,
        comic_merge_id,
        clickedTrait
      );

      if (!transactions) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBuyClicked(false);
        return;
      }

      let opt_in_tx;
      let asset_transfer_tx;
      let comic_transfer_tx;

      const signedTransactions = await signTransactions([
        algosdk.encodeUnsignedTransaction(transactions[0]),
        algosdk.encodeUnsignedTransaction(transactions[1]),
        algosdk.encodeUnsignedTransaction(transactions[2]),
      ]);

      // Encode transactions
      opt_in_tx = encode(signedTransactions[0]);
      asset_transfer_tx = encode(transactions[1].toByte());
      comic_transfer_tx = encode(transactions[2].toByte());
      toast.info("Preparing your JUNK!");
      // Send the encoded signed transactions to the server
      const res = await axios.post(`${API_ENDPOINT}/merge-comic/`, {
        opt_in_tx,
        asset_transfer_tx,
        comic_transfer_tx,
        key: key,
      });

      // Handle server response
      if (res.status === 200) {
        const { transaction, assetid, comicid } = res.data;
        toast.success("Successfully merged your Comic!");
        playSound(success);
        setIsMergeDone(true);
        setIsBuyClicked(false);
        // Update state with new comics
      } else {
        const errorMessage = res.data || "Something went wrong while merging!";
        toast.error(errorMessage);
        setIsBuyClicked(false);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBuyClicked(false);
    }
  }

  return (
    <div className="claim-container">
      <div className="kioskmain">
        <div className="closeposition">
          <Link
            to="/"
            type="button"
            className="btn-close"
            aria-label="Close"
          ></Link>
        </div>
        <h2>Thank you for being Mental!</h2>
        <p></p>
        <h5>
          Merge your Digital comic with your physical and claim one of 3 super
          rare JUNK traits!
        </h5>
        <p></p>
        <h4>STEP 1 - Select a JUNK</h4>
        <div className="claim-comic-trait-flex">
          {traits.map((trait) => (
            <div
              key={trait.id}
              className={`claim-comic-trait ${
                clickedTrait === trait.id ? "selected" : ""
              }`}
              onClick={() => handleTraitClick(trait.id)}
            >
              <AssetBoxForSwap asset_id={trait.id} />
              <div className="claim-trait-info">
                <h6>{trait.description}</h6>
              </div>
            </div>
          ))}
        </div>
        <p></p>
        {/* <button className="btn btn-info" onClick={() => setIsMergeDone(true)}>
          merge
        </button> */}
        <div
          className={`merge-step-2 ${clickedTrait ? "ease-in" : ""}`}
          style={{ visibility: clickedTrait ? "visible" : "hidden" }}
        >
          <h4>STEP 2 - Claim with your wallet</h4>
          {walletAddress ? (
            <button
              className="btn btn-info"
              onClick={handleComicMerge}
              disabled={isMergeDone}
            >
              CLAIM
            </button>
          ) : (
            <>
              <div className="not-connected-button">
                <ConnectDropdown
                  isOpen={dropdownOpen}
                  onClose={() => setDropdownOpen(false)}
                  ref={dropdownRef}
                />
              </div>
              Connect First
            </>
          )}
        </div>
        <div
          className={`merge-step-2 ${isMergeDone ? "ease-in" : ""}`}
          style={{ visibility: isMergeDone ? "visible" : "hidden" }}
        >
          <p></p>
          <h4>
            STEP 3 - Got to our Trait Shop and put that <span>JUNK</span> trait
            on!
          </h4>
          <Link to="/marvin/traitshop" className="trait-shop-img">
            <img src={traitshop} alt="traitshop" />
          </Link>
        </div>
        {/* Add logic to handle backend validation and claim reward here */}
      </div>
    </div>
  );
};

export default ComicClaim;
