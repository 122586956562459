import React, { useState, useEffect } from "react";

const CountdownTimer = ({ huntEndTimestamp }) => {
  const [remainingTime, setRemainingTime] = useState(
    Math.max(huntEndTimestamp - Date.now(), 0)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) =>
        Math.max(huntEndTimestamp - Date.now(), 0)
      );
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [huntEndTimestamp]);

  const formatTime = (timeInMs) => {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <span>{remainingTime > 0 ? formatTime(remainingTime) : "COMPLETE!"}</span>
  );
};

export default CountdownTimer;
